import { render, staticRenderFns } from "./TotpSetup.vue?vue&type=template&id=5f561113&scoped=true&"
import script from "./TotpSetup.vue?vue&type=script&lang=js&"
export * from "./TotpSetup.vue?vue&type=script&lang=js&"
import style0 from "./TotpSetup.vue?vue&type=style&index=0&id=5f561113&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f561113",
  null
  
)

export default component.exports