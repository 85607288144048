<template>
    <div>
        <div >
            <div class=" footer">
                <div>
                    <a href="#" v-on:click.prevent="showPrivacyPolicy">
                        Privacy Policy
                    </a>
                    <span class="divider">|</span>
                    <a href="#" v-on:click.prevent="showTermsOfUse">
                        Terms of Use
                    </a>
                    <span class="divider">|</span>
                    <a href="#" v-on:click.prevent="showLegalNotice">
                        Legal Notice
                    </a>
                </div>
                <div class="copyright">
                    &copy; {{ currentYear }} Employee Navigator, LLC
                </div>
            </div>
        </div>

        <info-modal v-bind:show-modal="showInfoModal"
                    v-on:close="closeInfoModal"
                    v-bind:show-footer="false"
                    :title="infoModalTitle">

            <template v-slot:body>
                <div v-if="infoModalTitle === 'Privacy Policy'">
                    <div ref="terms_privacy"></div>
                </div>
                <div v-if="infoModalTitle === 'Terms of Use'">
                    <div ref="terms_terms"></div>
                </div>
                <div v-if="infoModalTitle === 'Legal Notice'">
                    <div ref="terms_legal"></div>
                </div>
            </template>
        </info-modal>
    </div>
</template>

<script>
    import EnModal from '@front-end/en-components/src/lib-components/EnModal.vue';

    export default {
        name: 'LoginFooter',
        components: {
            "info-modal": EnModal
        },
        data() {
            return {
                currentYear: new Date().getFullYear(),
                showInfoModal: false,
                infoModalTitle: ""
            };
        },
        methods: {
            showPrivacyPolicy() {
                this.infoModalTitle = "Privacy Policy";
                this.showInfoModal = true;
                fetch("/benefits/Account/TermsOfUse_Privacy")
                    .then(response => response.text())
                    .then(data => {
                        this.$refs.terms_privacy.innerHTML = data;
                    })
            },
            showTermsOfUse() {
                this.infoModalTitle = "Terms of Use";
                this.showInfoModal = true;
                fetch("/benefits/Account/TermsOfUse_Terms")
                    .then(response => response.text())
                    .then(data => {
                        this.$refs.terms_terms.innerHTML = data;
                    })
            },
            showLegalNotice() {
                this.infoModalTitle = "Legal Notice";
                this.showInfoModal = true;
                fetch("/benefits/Account/TermsOfUse_Legal")
                    .then(response => response.text())
                    .then(data => {
                        this.$refs.terms_legal.innerHTML = data;
                    })
            },
            closeInfoModal() {
                this.showInfoModal = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    .footer {
        font-size: 0.9em;
        margin-top: 100px;
    }

    .copyright {
        color: #666;
        margin-top: 6px;
    }

    .divider {
        margin-left: 4px;
        margin-right: 4px;
    }
</style>
